import React from "react"
import Wrapper from "../grid/wrapper"
import Video from "../video"

const VideoSection = () => {
  return (
    <Wrapper style={{ marginTop: "6rem" }}>
      <Video src="https://www.youtube.com/embed/P_1fhi_G2Fo" />
    </Wrapper>
  )
}

export default VideoSection
