import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { TweenMax, Back } from "gsap"
import colors from "../../styles/colors"
import { breakpoints, maxWidthContainer } from "../../styles/breakpoints"
import { gutter } from "../../styles/gutter"
import Burger from "./burgerIcon.js"
import Row from "../grid/row"
import Column from "../grid/column"
import logo from "../../images/logo.svg"
import useWindowSize from "../../utils/useWindowSize"

const NavBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  background-color: ${colors.primaryDark};
  color: ${colors.tertiary};
  top: 0;
  width: 100vw;
  box-sizing: border-box;
  z-index: 100;

  a {
    text-decoration: none;
    color: ${colors.tertiary};
    line-height: 21px;
    font-size: 14px;
    font-weight: bold;
  }

  @media screen and (min-width: ${breakpoints.large}px) {
    justify-content: space-evenly;
  }
`

const NavBarWrapper = styled.div`
  width: 100%;
  max-width: calc(100% - ${gutter * 4}px);
  margin: 15px auto;

  @media only screen and (min-width: ${breakpoints.phone}px) {
    max-width: ${maxWidthContainer.phone}px;
  }

  @media only screen and (min-width: ${breakpoints.tablet}px) {
    max-width: ${maxWidthContainer.tablet}px;
  }

  @media only screen and (min-width: ${breakpoints.desktop}px) {
    max-width: ${maxWidthContainer.desktop}px;
  }

  @media only screen and (min-width: ${breakpoints.large}px) {
    max-width: ${maxWidthContainer.large}px;
  }
`

const Bar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Navigator = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const RocaLogo = styled.a`
  display: flex;
  color: ${colors.tertiary};
  opacity: 1;

  ::after {
    content: "";
    width: 70px;
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${logo});
    color: ${colors.tertiary};
  }
`

const SectionsLinksBar = styled.div`
  display: flex;
  width: 100%;
  margin-left: auto;
  justify-content: space-between;

  @media screen and (min-width: ${breakpoints.large}px) {
    justify-content: space-between;
    align-items: center;
    width: 75%;
  }

  a {
    text-decoration: none;
    color: ${colors.tertiary};
    text-align: center;
    transition: all 0.4s ease;
    font-size: 12px;
    &:hover {
      color: ${colors.tertiaryLight};
    }
  }

  .whiteLink {
    background: ${colors.tertiary};
    border-radius: 15px;
    color: ${colors.primaryDark};
    padding: 5px 15px;
    margin: -5px -15px;

    transition: all 0.4s ease;

    &:hover {
      color: ${colors.tertiaryLight};
    }
  }
`

const CollapsedMenu = styled.div`
  display: flex;
  margin: auto 0;
  flex-direction: column;
  color: ${colors.tertiary};
  background-color: ${colors.primaryDark};
  padding: 0;
  position: fixed;
  width: 100%;
  top: 60px;
  height: calc(100vh - 60px);
  box-sizing: border-box;
  z-index: 100;

  opacity: 0;
  visibility: hidden;

  a {
    text-decoration: none;
    color: ${colors.tertiary};
    cursor: pointer;

    display: flex;
    justify-content: flex-center;
    align-items: center;
    height: 60px;
    padding: 0;
    line-height: 24px;
    font-size: 16px;
    font-weight: bold;

    position: relative;

    opacity: 1;
    transition: all 0.4s ease;
  }
`

const CollapsedItemsContainer = styled.div`
  width: 100%;
  max-width: calc(100% - 60px);
  margin: 60px auto;
`

const CollapsedWhite = styled.span`
  background: ${colors.tertiary};
  border-radius: 20px;
  color: ${colors.primaryDark};
  padding: 8px 25px;
  width: fit-content;
  height: fit-content;
`

//data:
const sections = [
  {
    name: "Про компанію Roca. Наша історія",
    anchor: "#intro",
  },
  {
    name: "Категорії продуктів",
    anchor: "#categories",
  },
  {
    name: "Виробництво та інновації",
    anchor: "#factory",
  },
  {
    name: "Галереї Roca",
    anchor: "#showroom",
  },
  {
    name: "Контакти",
    anchor: "#visit-us",
  },
  {
    name: "Завантажити Каталог",
    anchor: "#footer",
  },
]

//component:
const NavBar = () => {
  const size = useWindowSize()

  const [width, setWidth] = useState(null)
  const [viewNavItems, setViewNavItems] = useState(false)

  useEffect(() => {
    if (viewNavItems) {
      TweenMax.to("#menu", 0.3, {
        autoAlpha: 1,
      })
      TweenMax.staggerTo(
        `.navItem`,
        0.5,
        { autoAlpha: 1, delay: 0.1, ease: Back.easeOut },
        0.1
      )
    } else {
      TweenMax.to("#menu, .navItem", 0.3, {
        autoAlpha: 0,
      })
    }

    setWidth(size.width)
  }, [viewNavItems, size])

  //collapsed menu:
  return width < breakpoints.large ? (
    <Navigator>
      <NavBarContainer>
        <NavBarWrapper>
          <Row>
            <Column xs={12}>
              <Bar>
                <RocaLogo href="." onClick={() => setViewNavItems(false)} />
                <Burger
                  isOpen={viewNavItems}
                  handleClick={() => setViewNavItems(!viewNavItems)}
                />
              </Bar>
            </Column>
          </Row>
        </NavBarWrapper>
      </NavBarContainer>
      <CollapsedMenu id="menu">
        <CollapsedItemsContainer>
          {sections.map((section, i) => (
            <a
              key={i}
              href={section.anchor}
              onClick={() => setViewNavItems(!viewNavItems)}
              className="navItem"
            >
              {section.name === "Pide presupuesto" ? (
                <CollapsedWhite>{section.name}</CollapsedWhite>
              ) : (
                section.name
              )}
            </a>
          ))}
        </CollapsedItemsContainer>
      </CollapsedMenu>
    </Navigator>
  ) : (
    //items on navbar:
    <NavBarContainer>
      <NavBarWrapper>
        <Row>
          <Column xs={12}>
            <RocaLogo href="." />
            <SectionsLinksBar>
              {sections.map((section, i) => (
                <a
                  key={i}
                  className={
                    section.name === "Pide presupuesto" ? "whiteLink" : ""
                  }
                  href={section.anchor}
                >
                  {section.name}
                </a>
              ))}
            </SectionsLinksBar>
          </Column>
        </Row>
      </NavBarWrapper>
    </NavBarContainer>
  )
}

export default NavBar
