import React from "react"
import styled from "styled-components"

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16/9 ratio */
  padding-top: 30px; /* IE6 workaround*/

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
const Video = ({ src, videoTitle, ...props }) => (
  <VideoContainer>
    <iframe
      src={src}
      title={videoTitle}
      allow="accelerometer: autoplay; encrypted-media: gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </VideoContainer>
)
export default Video
