import React from "react"
import styled from "styled-components"
import fonts from "../../fonts"
import colors from "../../styles/colors"
import Wrapper from "../grid/wrapper"
import Row from "../grid/row"
import Column from "../grid/column"
import logoRoca from "../../images/logo-roca.svg"
import FooterSocial from "./footerSocial"
import breakpoints from "../../styles/breakpoints"

const FooterS = styled.footer`
  width: 100%;

  background: ${colors.primary};
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  color: ${colors.tertiary};
  font-family: ${fonts.helvetica};

  a {
    text-decoration: none;
    color: ${colors.tertiary};
    transition: all 0.4s ease;

    :hover {
      text-decoration: underline;
      color: ${colors.primaryLight};
    }
  }
`

const FooterLegal = styled.div`
  margin-top: 15px;
`

const LogoCopy = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const FooterLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  a {
    flex-grow: 1;
    width: 100%;
    color: #ffffff;
    margin-bottom: 15px;

    @media ${breakpoints.tablet} {
      width: 25%;
      margin-bottom: 5px;
    }
  }
`

const Footer = () => {
  return (
    <FooterS id="footer">
      <Wrapper>
        <Row>
          <Column sm="6" md="9" mdOffset="1" lgOffset="0">
            <LogoCopy>
              <img alt="Roca - Logo" src={logoRoca} />
              <FooterLinks>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://publications.eu.roca.com/42322/1299903/"
                >
                  Каталог ROCA
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://publications.eu.roca.com/42322/1299917/"
                >
                  Прайс Лист
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://publications.eu.roca.com/42322/1300300/"
                >
                  Змішувачі
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://publications.eu.roca.com/42322/1299891/"
                >
                  Все для Ванної Кімнати
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://publications.eu.roca.com/42322/1299876/"
                >
                  Душові Кабіни
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://publications.eu.roca.com/42322/1299889/"
                >
                  Вироби зі Stonex
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://publications.eu.roca.com/42322/1299886/"
                >
                  Вироби Touchless
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://publications.eu.roca.com/42322/1299887/"
                >
                  Інсталяції Duplo One
                </a>
              </FooterLinks>
              <FooterLegal>
                © Copyright 2021. Roca Sanitario, S.A. - All rights reserved.{" "}
                <a
                  href="http://www.roca.co.id/footer/legal_notice"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Legal notice
                </a>
                .
              </FooterLegal>
            </LogoCopy>
          </Column>
          <Column sm="6" md="3">
            <FooterSocial />
          </Column>
        </Row>
      </Wrapper>
    </FooterS>
  )
}

export default Footer
