import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import FacebookIco from "./FacebookIco"
import PhoneIco from "./PhoneIco"
import MailIco from "./MailIco"

const SocialContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 60px 0 0;

  @media ${breakpoints.tablet} {
    margin: 0 0 0 auto;
  }
`
const FooterSocialS = styled.ul`
  display: flex;
  padding: 0;

  li {
    list-style: none;
    display: flex;
    margin-right: 20px;
  }

  li:last-child {
    margin-right: 0;
  }
`

const SocialIcon = styled.a`
  background: ${colors.primary};
  border: 1px solid ${colors.tertiary};
  border-radius: 100% !important;
  width: 40px !important;
  height: 40px !important;
  transition: all 0.4s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 20px;
  }
  &:hover {
    background: #333333;
  }
`
export default () => (
  <SocialContainer>
    <FooterSocialS>
      <li>
        <SocialIcon href="tel:+380444990620" rel="noopener noreferrer">
          <PhoneIco />
        </SocialIcon>
      </li>
      <li>
        <SocialIcon href="mailto:info@roca.net.ua" rel="noopener noreferrer">
          <MailIco />
        </SocialIcon>
      </li>
      <li>
        <SocialIcon
          href="https://www.facebook.com/RocaUA"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookIco />
        </SocialIcon>
      </li>
    </FooterSocialS>
  </SocialContainer>
)
