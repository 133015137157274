import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import fonts from "../../fonts"
import Wrapper from "../grid/wrapper"
import Row from "../grid/row"
import Column from "../grid/column"
import breakpoints from "../../styles/breakpoints"
import ScrollAnimation from "react-animate-on-scroll"
import colors from "../../styles/colors"

const CategoriasTitle = styled.h2`
  font-size: 48px;
  line-height: 50px;
  font-family: ${fonts.roca};
  font-weight: bold;
  text-align: center;
  margin: 0 auto 40px;

  @media ${breakpoints.phone} {
    margin: 0 auto 80px;
    font-size: 40px;
    line-height: 45px;
  }
`
const Anchor = styled.div`
  display: block;
  position: relative;
  top: -60px;
  visibility: hidden;
`

const CategoriaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flext-start;
  justify-content: flex-start;
  justify-self: center;

  min-height: 100%;
  padding: 0 0 40px 0;
  box-sizing: border-box;
`

const CategoriaImage = styled.img`
  width: 100%;
  object-fit: cover;
  margin: 0 0 25px;
`

const CategoriaTitle = styled.h3`
  font-family: ${fonts.roca};
  font-size: 23px;
  font-weight: bold;
  line-height: 26px;
  margin: 0 0 20px 0;
`

const CategoriaDesc = styled.div`
  p {
    font-family: ${fonts.helvetica};
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 20px;
  }
`
const CategoriaLink = styled.a`
  width: fit-content;
  font-family: ${fonts.helvetica};
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  color: ${colors.tertiaryDark};
  margin: 0;
  cursor: pointer;
  transition: all 0.4s ease;

  :hover {
    text-decoration: underline;
    color: ${colors.primaryLight};
  }
`

const CategoriasSection = () => {
  const { allCategoriasYaml } = useStaticQuery(
    graphql`
      query {
        allCategoriasYaml(sort: { fields: order }) {
          edges {
            node {
              title
              link
              image {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
              desc
            }
          }
        }
      }
    `
  )

  console.log(allCategoriasYaml)

  return (
    <section>
      <Anchor id="categories" />
      <Wrapper>
        <Row>
          <ScrollAnimation animateIn="fadeIn">
            <Column xs={12}>
              <CategoriasTitle>Категорії продуктів</CategoriasTitle>
            </Column>
          </ScrollAnimation>
        </Row>
        <Row>
          {allCategoriasYaml.edges.map(categoria => (
            <Column xs={10} md={6} align="center">
              <ScrollAnimation animateIn="fadeInUp">
                <CategoriaContainer>
                  <CategoriaImage
                    src={categoria.node.image.childImageSharp.fluid.src}
                    alt={categoria.node.title}
                  />
                  <CategoriaTitle>{categoria.node.title}</CategoriaTitle>
                  <CategoriaDesc
                    dangerouslySetInnerHTML={{ __html: categoria.node.desc }}
                  />
                  <CategoriaLink
                    target="_blank"
                    rel="noopener noreferrer"
                    href={categoria.node.link}
                  >
                    Дивитись продукти.
                  </CategoriaLink>
                </CategoriaContainer>
              </ScrollAnimation>
            </Column>
          ))}
        </Row>
      </Wrapper>
    </section>
  )
}

export default CategoriasSection
