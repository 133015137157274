import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import sliderData from "../../data/sliderRocaGallery"
import ScrollAnimation from "react-animate-on-scroll"
import Wrapper from "../grid/wrapper"
import fonts from "../../fonts"
import breakpoints from "../../styles/breakpoints"
import Row from "../grid/row"
import Column from "../grid/column"
import ConnectedSliders from "../connectedSliders"

const SliderTitle = styled.h2`
  font-size: 48px;
  line-height: 50px;
  font-family: ${fonts.roca};
  font-weight: bold;
  text-align: center;
  margin: 0 auto 20px;

  @media ${breakpoints.tablet} {
    margin: 0 auto 40px;
  }
`

const SliderDesc = styled.p`
  font-family: ${fonts.helvetica};
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 40px;
  text-align: center;

  @media ${breakpoints.tablet} {
    margin: 2rem 0;
  }
`

const Section = styled.section`
  width: 100%;
`
const SectionSlider = styled.section`
  background-color: ${colors.primary};
  width: 100%;
`

const Anchor = styled.div`
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
`
const RocaGallerySliders = () => (
  <>
    <Section>
      <Anchor id="showroom" />
      <Wrapper>
        <Row>
          <Column xs={10} align="center">
            <SliderTitle>
              <ScrollAnimation animateIn="fadeIn">Галереї Roca</ScrollAnimation>
            </SliderTitle>
          </Column>
          <Column xs={10} align="center">
            <ScrollAnimation animateIn="fadeIn">
              <SliderDesc>
                Галереї Roca відображають прагнення компанії Roca до
                досконалості і її прихильність принципам постійного розвитку в
                галузі архітектури та дизайну. Галереї є місцем проведення
                виставок, дискусій, презентацій і культурних заходів. Двері
                виставкових просторів відкриті як для професіоналів, так і для
                широкої аудиторії, надаючи можливість кожному бажаючому
                ознайомитися з останніми новинками, продуктами та рішеннями
                Roca. Галереї в Лондоні, Шанхаї, Барселоні, Мадриді, Лісабоні та
                Пекіні забезпечують мультинаціональну платформу для обміну
                досвідом, тим самим будуючи всесвітню мережу для аналізу,
                оцінки, інтерпретації, розвитку та обміну інформацією про нові
                тенденції у галузі.
              </SliderDesc>
            </ScrollAnimation>
          </Column>
        </Row>
      </Wrapper>
    </Section>
    <SectionSlider>
      <Wrapper style={{ marginTop: "0px", marginBottom: "0px" }}>
        <Row>
          <Column xs={12}>
            <ScrollAnimation animateIn="fadeIn">
              <ConnectedSliders data={sliderData} />
            </ScrollAnimation>
          </Column>
        </Row>
      </Wrapper>
    </SectionSlider>
  </>
)

export default RocaGallerySliders
