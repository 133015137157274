import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import sliderData from "../../data/sliderHistory"
import ScrollAnimation from "react-animate-on-scroll"
import ConnectedSliders from "../connectedSliders"
import Wrapper from "../grid/wrapper"

const Section = styled.section`
  background-color: ${colors.primary};
  width: 100%;
`

const Anchor = styled.div`
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
`
const CocinasMedidas = () => (
  <Section>
    <Anchor id="personalizacion" />
    <Wrapper style={{ marginTop: "0px", marginBottom: "0px" }}>
      <ScrollAnimation animateIn="fadeIn">
        <ConnectedSliders data={sliderData} />
      </ScrollAnimation>
    </Wrapper>
  </Section>
)

export default CocinasMedidas
