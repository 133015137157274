import React from "react"
import styled from "styled-components"
import Wrapper from "../grid/wrapper"
import Row from "../grid/row"
import Column from "../grid/column"
import colors from "../../styles/colors"
import Phone from "../../images/phone.svg"
import Email from "../../images/email_icon.svg"
import Www from "../../images/www-icon.svg"
import breakpoints from "../../styles/breakpoints"

const Anchor = styled.div`
  display: block;
  position: relative;
  top: -60px;
  visibility: hidden;
`

const ContactContainer = styled.div``
const ContactHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const ContactTelf = styled.a`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.tertiaryDark};
  transition: all 0.4s ease;
  opacity: 1;
  text-decoration: none;
  margin-bottom: 20px;
  :hover {
    opacity: 0.6;
  }
`

const ContactEmail = styled.a`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.tertiaryDark};
  transition: all 0.4s ease;
  opacity: 1;
  text-decoration: none;
  margin-bottom: 20px;
  :hover {
    opacity: 0.6;
  }
`

const ContactWhatsapp = styled.a`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.tertiaryDark};
  transition: all 0.4s ease;
  opacity: 1;
  text-decoration: none;
  margin-bottom: 20px;
  :hover {
    opacity: 0.6;
  }
`

const Icon = styled.img`
  width: 24px;
  margin-right: 10px;
`

const ContactBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem;
  border: 1px solid #ccc;

  @media ${breakpoints.tablet} {
    margin-bottom: 100px;
  }
`

const Contact = () => {
  return (
    <section>
      <Anchor id="after-sales" />
      <Wrapper>
        <ContactContainer>
          <Row>
            <Column xs={12} align="center">
              <ContactHeader>
                <ContactBox>
                  <ContactTelf href="tel:+380444990620">
                    <Icon alt="phone-icon" src={Phone} /> +38 044 499 06 20
                  </ContactTelf>
                  <ContactEmail href="mailto:info@roca.net.ua">
                    <Icon alt="email-icon" src={Email} /> info@roca.net.ua
                  </ContactEmail>
                  <ContactWhatsapp href="http://www.roca.ua/">
                    <Icon alt="www" src={Www} /> www.roca.ua
                  </ContactWhatsapp>
                </ContactBox>
              </ContactHeader>
            </Column>
          </Row>
        </ContactContainer>
      </Wrapper>
    </section>
  )
}
export default Contact
