import React from "react"

export default () => (
  <svg
    width="12px"
    height="26px"
    viewBox="0 0 12 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M7.91962984,25.5251279 L7.91962984,12.7610695 L11.4430682,12.7610695 L11.9099998,8.36250435 L7.91962984,8.36250435 L7.92561614,6.16098012 C7.92561614,5.01376657 8.03461675,4.39906334 9.68234673,4.39906334 L11.8850569,4.39906334 L11.8850569,0 L8.36111971,0 C4.12830441,0 2.63846318,2.13377736 2.63846318,5.72211988 L2.63846318,8.36300248 L0,8.36300248 L0,12.7615677 L2.63846318,12.7615677 L2.63846318,25.5251279 L7.91962984,25.5251279 Z"
        id="Shape"
        fill="#FFFFFF"
      ></path>
    </g>
  </svg>
)
