import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../fonts"
import Wrapper from "../grid/wrapper"
import Row from "../grid/row"
import Column from "../grid/column"
import ScrollAnimation from "react-animate-on-scroll"

const Anchor = styled.div`
  display: block;
  position: relative;
  top: -60px;
  visibility: hidden;
`

const IntroText = styled.div`
  p {
    font-family: ${fonts.helvetica};
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: ${colors.tertiaryDark};
  }
`
const introSection = () => {
  return (
    <section>
      <Anchor id="intro" />
      <Wrapper>
        <Row>
          <Column xs={10} align="center">
            <IntroText>
              <ScrollAnimation animateIn="fadeIn">
                <p>
                  <strong>Roca</strong> – це іспанська компанія, яка є світовим
                  лідером у виробництві продукції для ванної кімнати. Roca існує
                  вже більше 100 років та була першою у розробці технологій
                  ресурсозбереження та екологічно чистого виробництва. Дизайн,
                  інновації та турбота про навколишнє середовище завжди були
                  основними цінностями для компанії Roca.
                </p>
              </ScrollAnimation>
            </IntroText>
          </Column>
        </Row>
      </Wrapper>
    </section>
  )
}
export default introSection
