import React, { useState, useEffect, useRef } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import styled from "styled-components"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import sliderArrow from "../../images/arrow-slider-rectangle.svg"
import sliderArrowNext from "../../images/arrow-slider-rectangle-next.svg"

//Slider arrows
function NextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      style={{
        ...style,
        height: "85px",
        width: "24px",
        backgroundImage: `url(${sliderArrowNext})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      className={className}
      role="button"
      tabIndex={0}
      onKeyDown={onClick}
      onClick={onClick}
    />
  )
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      style={{
        ...style,
        height: "85px",
        width: "24px",
        backgroundImage: `url(${sliderArrow})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      className={className}
      role="button"
      tabIndex={0}
      onKeyDown={onClick}
      onClick={onClick}
    />
  )
}

//Slider styles
const SlidersContainer = styled.div`
  max-width: 100%;
  flex-direction: column;
  display: flex;
  @media ${breakpoints.phone} {
    flex-direction: row;
  }
`

const SliderContainer = styled.div`
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  background-color: ${colors.primary};
  color: #fff;
  .slick-prev {
    left: 0;
    z-index: 10;
  }
  .slick-next {
    right: 0;
  }
  p {
    padding: 2rem 1rem 0 1rem;
    margin-top: 0;
    font-size: 0.98rem;
  }
  h4 {
    padding: 2rem 1rem 0 1rem;
    font-size: 2rem;
    margin: 0;
  }
  .slick-arrow:before {
    content: "";
  }
  @media ${breakpoints.phone} {
    width: 50%;
    max-height: none;
    overflow-y: hidden;
  }
`

function ConnectedSliders({ data }) {
  const [state, setState] = useState({
    nav1: null,
    nav2: null,
  })

  const sliderImages = useRef()
  const sliderDescription = useRef()

  useEffect(() => {
    setState({
      nav1: sliderDescription.current,
      nav2: sliderImages.current,
    })
  }, [])

  const { nav1, nav2 } = state

  const sliderImagesSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }

  const sliderDescriptionSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <SlidersContainer>
      <SliderContainer>
        <Slider
          asNavFor={nav2}
          ref={slider => (sliderDescription.current = slider)}
          {...sliderDescriptionSettings}
        >
          {data.map((item, index) => (
            <div>
              {item.title && <h4>{item.title}</h4>}
              <p>{item.description}</p>
            </div>
          ))}
        </Slider>
      </SliderContainer>
      <SliderContainer>
        <Slider
          asNavFor={nav1}
          ref={slider => (sliderImages.current = slider)}
          {...sliderImagesSettings}
        >
          {data.map((item, index) => (
            <img alt={item.description.substr(0, 10)} src={item.image} />
          ))}
        </Slider>
      </SliderContainer>
    </SlidersContainer>
  )
}

export default ConnectedSliders
