export default [
  {
    title: "Roca Barcelona Gallary",
    description:
      "Відтворений за проектом Карлоса Ферратера (Lucía and Carlos Ferrater’s OAB architectural studio in Barcelona) скляний фасад створює приголомшливі візуальні ефекти як вдень, так і в нічний час.",
    image: "./rcgallery_01.jpg",
  },
  {
    title: "Roca Madrid Gallary",
    description:
      "Простір, створений архітектурною студією Lamela (Estudio Lamela in Madrid), є унікальним місцем зустрічі і проведення культурних заходів для жителів і гостей Мадриду.",
    image: "./rcgallery_02.jpg",
  },
  {
    title: "Roca Lisboa Gallary",
    description:
      "Roca перелаштувала історичну будівлю в центрі Ліссабону в унікальний простір, де технології переплітаються з дизайном й атмосферою старого міста.",
    image: "./rcgallery_03.jpg",
  },
  {
    title: "Roca London Gallary",
    description:
      "Натхненням у створенні простору Зарою Хадід (Zaha Hadid Architects in London) стали різноманітні стани та форми води – головним природним ресурсом, з яким працює наша компанія.",
    image: "./rcgallery_04.jpg",
  },
  {
    title: "Roca Shanghai Gallary",
    description:
      "Цей багатофункціональний простір, який представляє експозицію продукції Roca, є місцем проведення зустрічей та заходів в галузі архітеркурий дизайну.",
    image: "./rcgallery_04.jpg",
  },
  {
    title: "Roca Beijing Gallary",
    description:
      "Цей простір був створений всесвітньовідомим китайським архітектором Ма Янсоном (Ma Yansong in Beijing), який використав аудіовізуальні ефекти як «другу шкіру» будівлі.",
    image: "./rcgallery_04.jpg",
  },
]
