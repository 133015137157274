import React from "react"
import SEO from "../components/seo"
import Map from "../components/map"
import Contact from "../components/contact"
import Layout from "../components/layout"
import NavBar from "../components/navBar"
import Hero from "../components/hero"
import SliderFabrica from "../components/sliderFabrica"
import IntroSection from "../components/introSection"
import CategoriasSection from "../components/categoriasSection"
import Footer from "../components/footer"
import SliderHistory from "../components/sliderHistory"
import VideoSection from "../components/VideoSection"
import RocaGallerySliders from "../components/RocaGallerySliders"

const IndexPage = () => (
  <Layout>
    <SEO title=" " description="" lang="es" />
    <NavBar />
    <Hero />
    <IntroSection />
    <SliderHistory />
    <VideoSection />
    <CategoriasSection />
    <SliderFabrica />
    <RocaGallerySliders />
    <Map />
    <Contact />
    <Footer />
  </Layout>
)

export default IndexPage
