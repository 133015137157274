import React, { useState } from "react"
import styled from "styled-components"
import { TweenMax, Power3 } from "gsap"
import fonts from "../../fonts"
import breakpoints from "../../styles/breakpoints"
import Wrapper from "../grid/wrapper"
import Row from "../grid/row"
import Column from "../grid/column"
import arrow from "../../images/arrow-slider.svg"
import SliderFabricaData from "../../data/sliderFabricaData"
import ScrollAnimation from "react-animate-on-scroll"

const Anchor = styled.div`
  display: block;
  position: relative;
  top: -60px;
  visibility: hidden;
`

const SliderTitle = styled.h2`
  font-size: 48px;
  line-height: 50px;
  font-family: ${fonts.roca};
  font-weight: bold;
  text-align: center;
  margin: 0 auto 20px;

  @media ${breakpoints.tablet} {
    margin: 0 auto 40px;
  }
`

const SliderDesc = styled.p`
  font-family: ${fonts.helvetica};
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 40px;
  text-align: center;

  @media ${breakpoints.tablet} {
    margin: 2rem 0;
  }
`

const Slider = styled.div`
  display: flex;
  align-items: center;

  position: relative;
  left: 0;
  width: 100%;

  margin: 0 -1px;

  @media ${breakpoints.tablet} {
    max-height: inherit;
  }

  img {
    min-width: 100%;
    padding: 0 1px;
    object-fit: cover;
    max-width: 100%;
  }
`

const ServiceSliderNavSwitchLeft = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: -23px;

  margin-top: -30px;

  user-select: none;
  z-index: 1;

  transform: rotate(180deg);

  border-radius: 50%;
  /* box-shadow: 0px 2px 19px 0px rgba(0,0,0, .2); */
  transition: 0.4s;

  &:hover {
    opacity: 0.8;
  }
`

const ServiceSliderNavSwitchRight = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: -23px;
  margin-top: -30px;
  user-select: none;
  z-index: 1;
  border-radius: 50%;
  /* box-shadow: 0px 2px 19px 0px rgba(0,0,0, .2); */
  transition: 0.4s;

  &:hover {
    opacity: 0.8;
  }
`

const SliderFabrica = () => {
  const [index, setIndex] = useState(0)

  const animate = i => {
    const slider = document.getElementsByClassName("slide")

    TweenMax.to(slider, 1.4, {
      autoAlpha: 1,
      x: (index + i) * 100 + "%",
      ease: Power3.easeInOut,
    })
  }

  const handlePrevious = i => {
    setIndex(index + i)
    animate(i)
  }

  const handleNext = i => {
    setIndex(index + i)
    animate(i)
  }

  return (
    <section>
      <Anchor id="factory" />
      <Wrapper>
        <Row>
          <Column xs={10} align="center">
            <SliderTitle>
              <ScrollAnimation animateIn="fadeIn">
                Виробництво та інновації
              </ScrollAnimation>
            </SliderTitle>
          </Column>
          <Column xs={10} align="center">
            <ScrollAnimation animateIn="fadeIn">
              <SliderDesc>
                Інновації в виробничих процесах на наших заводах і дбайливе
                ставлення до навколишнього середовища дозволили нам стати
                світовим виробником, з якого беруть приклад. Roca контролює
                процес створення та виробництва своїх продуктів, що в результаті
                забезпечує бездоганну якість всіх кінцевих виробів. Контроль
                якості на виробництві є багатостадійним і супроводжує кожний
                етап виробничого процесу.
              </SliderDesc>
              <SliderDesc>
                Технологічні інновації є важливим елементом для забезпечення
                постійного розвитку та постійного вдосконалення будь-якої
                діяльності. Саме тому найсучасніші технології використовуються
                на всіх заводах компанії та на всіх етапах виробничого процесу.
                Roca впроваджує інновації, починаючи від концепції та дизайну
                продукту, закінчуючи виробництвом, логістикою та продажем.
                Постійні технологічні інновації Roca мають лише одну мету –
                надати своїм клієнтам найкращий сервіс та найкращий продукт.
              </SliderDesc>
            </ScrollAnimation>
          </Column>
          <Column xs={12}>
            <ScrollAnimation animateIn="fadeInRight">
              <Slider>
                {SliderFabricaData.map((fabrica, idx) => (
                  <img
                    className="slide"
                    alt="fabrica 1"
                    src={fabrica}
                    key={idx}
                  />
                ))}

                {index !== 0 && (
                  <ServiceSliderNavSwitchLeft onClick={() => handlePrevious(1)}>
                    <img src={arrow} alt="Previous" />
                  </ServiceSliderNavSwitchLeft>
                )}

                {-index !== SliderFabricaData.length - 1 && (
                  <ServiceSliderNavSwitchRight onClick={() => handleNext(-1)}>
                    <img src={arrow} alt="Next" />
                  </ServiceSliderNavSwitchRight>
                )}
              </Slider>
            </ScrollAnimation>
          </Column>
          <Column xs={10} align="center">
            <ScrollAnimation animateIn="fadeIn">
              <SliderDesc>
                Roca Innovation Lab - це динамічна команда концептуальних
                дизайнерів, що мають багатопрофільний та міжнародний досвід,
                основною задачею якої є створення інноваційних пропозицій щодо
                продуктів, які виходять за рамки простого формального дизайну.
                Загалом, місія Roca Innovation Lab - допомогти виробам Roca, що
                мають диференційовані характеристики, стати продуктом із
                беззаперечними перевагами для наших клієнтів, а також бути
                вигідним нашій компанії. Roca Innovation Lab - це своєрідна
                «співпраця з майбутнім», що є запорукою стійкої та довготривалої
                компанії в глобальному мінливому середовищі, що з кожним днем
                стає все більш актуальним.
              </SliderDesc>
            </ScrollAnimation>
          </Column>
        </Row>
      </Wrapper>
    </section>
  )
}

export default SliderFabrica
