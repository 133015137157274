import React from "react"

export default () => (
  <svg
    width="27px"
    height="27px"
    viewBox="0 0 27 27"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="email_icon"
        transform="translate(0.000000, 4.000000)"
        fill="#FFFFFF"
        fill-rule="nonzero"
      >
        <g id="Group-3">
          <g id="mail-(1)">
            <polygon id="Path" points="18 10.1240667 27 16 27 4"></polygon>
            <polygon
              id="Path"
              points="7.10542736e-15 4 7.10542736e-15 16 9 10.1240667"
            ></polygon>
            <path
              d="M25.3569636,2.13162821e-14 L1.64303639,2.13162821e-14 C0.797804266,2.13162821e-14 0.127038896,0.670599805 7.10542736e-15,1.53408718 L13.5,11 L27,1.53408718 C26.8729611,0.670599805 26.2021957,2.13162821e-14 25.3569636,2.13162821e-14 Z"
              id="Path"
            ></path>
            <path
              d="M16.3633283,11.0016615 L13.9659262,12.5501558 C13.8236069,12.6415369 13.6626506,12.6863966 13.5,12.6863966 C13.3373494,12.6863966 13.1763931,12.6415369 13.0340738,12.5501558 L10.6366717,11 L7.10542736e-15,17.5977155 C0.130459337,18.3869159 0.798004518,19 1.64006024,19 L25.3599398,19 C26.2019955,19 26.8695407,18.3869159 27,17.5977155 L16.3633283,11.0016615 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
