import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import ScrollAnimation from "react-animate-on-scroll"
import useWindowSize from "../../utils/useWindowSize"
import colors from "../../styles/colors"
import fonts from "../../fonts/index"
import breakpoints from "../../styles/breakpoints"
import arrowDown from "../../images/arrow_down.svg"
import HeroBackgroundImg from "../../images/hero-bg.jpg"

const HeroSection = styled.section`
  background-image: url(${HeroBackgroundImg});
  background-size: cover;
  background-position: center;
  text-align: center;
  color: ${colors.tertiary};
  position: relative;
  margin: 0;
  height: 60vh;
  width: 100%;
  animation: fadein 2s;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media ${breakpoints.tablet} {
    height: 100vh;
  }
`

const HeroBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-color: #000;
    opacity: 0.6;
  }

  img,
  video {
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
  }

  @media screen and (min-width: 768px) {
    position: absolute;
    height: 100vh;
    top: 0;
    left: 0;

    &:before {
      opacity: 0.5;
    }
  }
`

const HeroLink = styled(Link)`
  position: absolute;
  bottom: 0;
  display: block;
  padding-bottom: 40px;
  width: 30px;
  height: 30px;

  ::after {
    content: "";
    position: absolute;
    top: 8px;
    left: 8px;
    width: 15px;
    height: 10px;
    background-repeat: no-repeat;
    background-image: url(${arrowDown});
    animation: flip-flop 0.8s infinite;
  }

  @keyframes flip-flop {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(0, 10px);
    }
  }

  transition: all 0.4s ease;
  opacity: 1;

  :hover {
    opacity: 0.6;
  }
`
const HeroTitle = styled.h1`
  font-size: 47px;
  line-height: 53px;
  font-family: ${fonts.roca};
  font-weight: bold;
  padding: 0 15px;

  @media ${breakpoints.tablet} {
    font-size: 69px;
    line-height: 78px;
  }
`

const HeroUpper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`

const Hero = () => {
  const size = useWindowSize()

  const [height, setHeight] = useState(null)
  const [width, setWidth] = useState(null)

  useEffect(() => {
    setHeight(size.height)
    setWidth(size.width)
    // eslint-disable-next-line
  }, [])

  return (
    <HeroSection id="hero" height={height}>
      <HeroBackground>
        {width < 768 ? (
          <img src={HeroBackgroundImg} alt="Про компанію Roca. Наша історія" />
        ) : (
          <video
            controls={false}
            type="video/mp4"
            poster={HeroBackgroundImg}
            autoPlay
            muted
            loop
          >
            <source src="video_hero.mp4" type="video/mp4" />
          </video>
        )}
      </HeroBackground>
      <HeroUpper>
        <ScrollAnimation animateIn="fadeIn">
          <HeroTitle>
            Про компанію Roca.
            <br />
            Наша історія
          </HeroTitle>
        </ScrollAnimation>
        <HeroLink to={"/#intro"} />
      </HeroUpper>
    </HeroSection>
  )
}
export default Hero
