import React from "react"
import styled from "styled-components"
import fonts from "../../fonts"
import Wrapper from "../grid/wrapper"
import ScrollAnimation from "react-animate-on-scroll"
import Row from "../grid/row"
import Column from "../grid/column"
import breakpoints from "../../styles/breakpoints"

const Anchor = styled.div`
  display: block;
  position: relative;
  top: -60px;
  visibility: hidden;
`
const MapTitle = styled.h2`
  font-size: 48px;
  line-height: 54px;
  font-family: ${fonts.roca};
  font-weight: bold;
  text-align: center;
  margin: 0 auto 0;

  @media ${breakpoints.tablet} {
    margin: 0 0 20px;
  }
`

const MapDesc = styled.p`
  font-family: ${fonts.helvetica};
  font-size: 20px;
  line-height: 30px;
  margin: 0 0 40px;
  text-align: center;

  @media ${breakpoints.tablet} {
    margin: 0 0 60px;
  }
`

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 440px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
`

const MapSection = styled.section`
  width: 100%;
`

const Map = () => {
  return (
    <MapSection>
      <Anchor id="visit-us" />
      <Wrapper>
        <Row>
          <Column xs={10} align="center">
            <ScrollAnimation animateIn="fadeIn">
              <MapTitle>Контакти</MapTitle>
            </ScrollAnimation>
          </Column>
          <Column xs={10} align="center">
            <ScrollAnimation animateIn="fadeIn">
              <MapDesc>Представництво «Roca»</MapDesc>
            </ScrollAnimation>
          </Column>
        </Row>
        <Row>
          <Column xs={12}>
            <MapContainer>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2538.5797604927648!2d30.38608911606917!3d50.48616667948041!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNTDCsDI5JzEwLjIiTiAzMMKwMjMnMTcuOCJF!5e0!3m2!1ses!2ses!4v1611995738920!5m2!1ses!2ses"
                title="Roca Ukraine"
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
              ></iframe>
            </MapContainer>
          </Column>
        </Row>
      </Wrapper>
    </MapSection>
  )
}

export default Map
